import { useState, useEffect, useContext, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  ConnectionType,
  IDriver,
  IPartialDriver,
  UserRole,
} from "../../models";
import { Col, Container, Row, Spinner } from "reactstrap";
import { Form } from "react-bootstrap";
import Loader from "../../components/Loader";
import PromptWithModal from "../../components/PromptWithModal";
import { hideFlyout } from "../../redux/actions/flyoutActions";
import { Flyout } from "../../components/Flyout";
import { getDisplayedBasicDriverProperties } from "./components/ConnectionFunctions";
import { useConnectionSetup } from "../../hooks/useConnectionSetup";
import {
  ConnectionCrudCalls,
  useConnectionCrudCalls,
} from "../../hooks/useConnectionCrudCalls";
import {
  ConnectionContext,
  IConnectionContext,
} from "./components/ConnectionContext";
import { IConnectionInfo } from "./models/IConnectionInfo";
import { InitialSetupContext } from "../initialSetup/Setup/InitialSetupContext";
import { ConnectionTabs } from "./components/ConnectionTabs";
import classNames from "classnames";
import { useCacheConnectionSetup } from "../../hooks/useCacheConnectionSetup";
import TitleRow from "./components/TitleRow";
import { IDriverExtended } from "../../bffmodels/IDriverExtended";
import { IFullPermission } from "./components/PermissionsCard";
import { DynamicPropsStatus } from "./components/DynamicConnectionProperty";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNameAvailabilityCheck } from "../../hooks/useNameAvailabilityCheck";
import { ButtonType, CDataButton } from "../../components/buttons/CDataButton";

interface IEditConnectionProps {
  connectionId?: string;
  driverType?: string;
  handleNavigation?: (onNavigate: () => void) => void;
  hashKey?: string;
  isCacheConnection?: boolean;
  isInitialSetupPage?: boolean;
  isOEMConnection?: boolean;
  nextStep?: () => void;
  overrideConnectionType?: boolean;
  updatePreventNavigation?: (
    preventNavigation: boolean,
    preventNavigationTitle: string,
    preventNavigationMessage: string,
  ) => void | undefined;
  redirectUrl?: string;
}

function EditConnection(props: IEditConnectionProps) {
  const location = useLocation();
  const formRef = useRef<HTMLFormElement>(null);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const driverList = useAppSelector((state) => state.driversList);
  const { getRestrictedNames, pendingFetch } = useNameAvailabilityCheck();

  const initialSetupContext = useContext(InitialSetupContext);

  const connections = (location.state as any)?.connections;
  const overrideConnectionType =
    (location.state as any)?.overrideConnectionType ||
    props.overrideConnectionType;

  const initialConnectionId =
    (location.state as any)?.connectionId || props.connectionId;

  const [connectionId, setConnectionId] = useState<string>(initialConnectionId);

  const [driverInfo, setDriverInfo] = useState<IDriverExtended>();

  const [connectionInfo, setConnectionInfo] = useState<IConnectionInfo>({
    existingConnectionName: "",
    connectionLastModified: "",
    existingConnectionType: ConnectionType.Shared,
    selectedConnectionType: ConnectionType.Shared,
    cacheJobs: [],
    isGrantTypeClient: false,
    isTested: false,
  });

  const [currentTab, setCurrentTab] = useState<any>("");
  const [oauthProps, setOauthProps] = useState<{ [key: string]: string }>({});
  const [usersList, setUsersList] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<IFullPermission[]>([]);
  const [chameleonId, setChameleonId] = useState<string>("");
  const [saveAndTestFailed, setSaveAndTestFailed] = useState<boolean>(false);
  const [userDefinedProps, setUserDefinedProps] = useState<string[]>([]);
  const [hasUserConnectionString, setHasUserConnectionString] =
    useState<boolean>(false);
  const [currentlyCreatingReports, setCurrentlyCreatingReports] =
    useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [file, setFile] = useState<string>("");
  const [walletFilePresent, setWalletFilePresent] = useState<boolean>(false);
  const [oauthButtonInvalid, setOauthButtonInvalid] = useState(false);
  const [dynamicPropsStatus, setDynamicPropsStatus] =
    useState<DynamicPropsStatus>("incomplete");
  const [unavailableNames, setUnavailableNames] = useState<string[]>([]);

  const driverType: string =
    props.driverType ||
    (props.isInitialSetupPage
      ? initialSetupContext.selectedConnection.driver
      : (location.state as any)?.driverType);

  // Failsafe to prevent errors if either of these occurs:
  // 1. The user navigates to the add-initial-connection step of the onboarding wizard without selecting a connection
  // 2. The connections/edit page is accessed directly and not from the connections list
  if (!driverType) {
    if (props.isInitialSetupPage) {
      Navigate({ to: "/initial-setup" });
    } else {
      Navigate({ to: "/connections" });
    }
    return null;
  }

  const contextValues: IConnectionContext = {
    chameleonId: chameleonId,
    connectionId: connectionId,
    connectionInfo: connectionInfo,
    connections: connections,
    currentlyCreatingReports: currentlyCreatingReports,
    currentTab: currentTab,
    driverInfo: driverInfo!,
    driverType: driverType,
    dynamicPropsStatus: dynamicPropsStatus,
    formRef: formRef,
    hasUserConnectionString: hasUserConnectionString,
    isCacheConnection: props.isCacheConnection ?? false,
    isInitialSetupPage: props.isInitialSetupPage ?? false,
    isOEMConnection: props.isOEMConnection ?? false,
    loading: loading,
    oauthProps: oauthProps,
    oauthButtonInvalid: oauthButtonInvalid,
    overrideConnectionType: overrideConnectionType,
    permissions: permissions,
    processingRequest: processingRequest,
    saveAndTestFailed: saveAndTestFailed,
    selectedFile: file,
    setChameleonId: setChameleonId,
    setConnectionId: setConnectionId,
    setConnectionInfo: setConnectionInfo,
    setCurrentlyCreatingReports: setCurrentlyCreatingReports,
    setCurrentTab: setCurrentTab,
    setDriverInfo: setDriverInfo,
    setDynamicPropsStatus: setDynamicPropsStatus,
    setHasUserConnectionString: setHasUserConnectionString,
    setLoading: setLoading,
    setOauthProps: setOauthProps,
    setOauthButtonInvalid: setOauthButtonInvalid,
    setPermissions: setPermissions,
    setProcessingRequest: setProcessingRequest,
    setSaveAndTestFailed: setSaveAndTestFailed,
    setSelectedFile: setFile,
    setUnavailableNames: setUnavailableNames,
    setUnsavedChanges: setUnsavedChanges,
    setUserDefinedProps: setUserDefinedProps,
    setUsersList: setUsersList,
    setWalletFilePresent: setWalletFilePresent,
    unavailableNames: unavailableNames,
    unsavedChanges: unsavedChanges,
    updatePreventNavigation: props.updatePreventNavigation,
    userDefinedProps: userDefinedProps,
    usersList: usersList,
    walletFilePresent: walletFilePresent,
  };

  let connectionSetup: {
    getDriverInfo: () => Promise<IDriver | undefined>;
    initializeConnectionState: (
      driverType: string,
      connectionId: string,
    ) => Promise<IDriverExtended | undefined>;
    initializeUserPermissions: (
      driverType: string,
      connectionId: string | undefined,
    ) => Promise<IFullPermission[]>;
  };

  // TODO: CLOUD-12781: Refactor to use a single hook for both cache and non-cache connections
  if (props.isCacheConnection) {
    connectionSetup = useCacheConnectionSetup(contextValues); // eslint-disable-line
  } else {
    connectionSetup = useConnectionSetup(contextValues); // eslint-disable-line
  }

  // TODO: CLOUD-12781: Refactor to move these hooks above the check for driverType
  // eslint-disable-next-line
  const connectionCrudCalls: ConnectionCrudCalls = useConnectionCrudCalls(
    contextValues,
    props.updatePreventNavigation!,
    props.nextStep,
  );

  // eslint-disable-next-line
  useEffect(() => {
    async function initializeData() {
      const masterModel = await connectionSetup.initializeConnectionState(
        driverType,
        connectionId,
      );

      // Map the drivers into a new array to avoid Redux state mutations
      const mappedDrivers = driverList.drivers!.map(
        (driver: IPartialDriver) => driver,
      );
      const niceName =
        mappedDrivers.filter(
          (driver: IPartialDriver) => driver.driver === masterModel?.name,
        )[0]?.niceName ?? "";
      if (masterModel) masterModel.niceName = niceName;
      setDriverInfo(masterModel);

      if (masterModel?.connectionId) setConnectionId(masterModel.connectionId);
      setWalletFilePresent(!!masterModel?.walletFilePresent);

      // Set the list of unavailable names to avoid naming conflicts
      const names = await getRestrictedNames({
        excludedName: masterModel?.existingConnectionName,
      });
      setUnavailableNames(names ?? []);

      // Query users cannot load user permissions.
      const userPermissions =
        user.role === UserRole.Query
          ? []
          : await connectionSetup.initializeUserPermissions(
              driverType,
              connectionId,
            );
      setPermissions(userPermissions);

      if (masterModel) {
        setConnectionInfo(masterModel);
        setUserDefinedProps(masterModel.userCredentialPropertyNames);
      }
      setLoading(false);
    }

    dispatch(hideFlyout());
    initializeData();

    setConnectionInfo({
      ...connectionInfo,
      isTested: contextValues.connectionInfo.isTested,
    });
  }, []); // eslint-disable-line

  const finishCustomReports = () => {
    setCurrentlyCreatingReports(false);
    props.nextStep!();
  };

  if (loading || pendingFetch) {
    return <Loader />;
  }

  if (connectionInfo == null) {
    throw new Error("Failed to get driver info.");
  }

  const className = classNames({
    "add-initial-connection": props.isInitialSetupPage,
    "edit-connection": props.isCacheConnection,
    "p-0": !props.isCacheConnection,
  });
  const displayedBasicDriverProperties = getDisplayedBasicDriverProperties(
    contextValues,
    "EditConnection",
  );

  function getParams() {
    if (connectionId == null) {
      return new URLSearchParams({
        cdata_connection_status: "none",
      });
    } else {
      return new URLSearchParams({
        cdata_connection_id: connectionId,
        cdata_connection_name: connectionInfo.existingConnectionName ?? "",
        cdata_connection_status: connectionInfo.isTested ? "success" : "error",
      });
    }
  }

  function navigateToRedirectUrl() {
    let redirectUrl: URL;
    if (props.redirectUrl) {
      redirectUrl = new URL(props.redirectUrl);
      const updatedParams = new URLSearchParams(redirectUrl.search);
      getParams().forEach((value, key) => {
        updatedParams.append(key, value);
      });
      redirectUrl.search = updatedParams.toString();
      window.location.href = redirectUrl?.toString();
    }
  }

  return (
    <ConnectionContext.Provider value={contextValues}>
      <Container fluid className={className}>
        <Row>
          <Col>
            <Form
              id="connectionProps"
              data-testid="form-edit-connection"
              ref={formRef}
              onSubmit={(event: any) =>
                connectionCrudCalls.handleConnectionSubmit(
                  event,
                  displayedBasicDriverProperties,
                )
              }
            >
              {props.isOEMConnection ? (
                <>
                  <CDataButton
                    buttonType={ButtonType.PrimaryOutline}
                    className="return-to-company-btn"
                    onClick={() => navigateToRedirectUrl()}
                  >
                    <i className="fa fa-solid fa-arrow-left" />
                    Return to {user?.parentAccountOrganization}
                  </CDataButton>
                </>
              ) : null}
              <TitleRow
                connectionCrudCalls={connectionCrudCalls}
                displayedBasicDriverProperties={displayedBasicDriverProperties}
                finishCustomReports={finishCustomReports}
                handleNavigation={props.handleNavigation!}
                pageName={connectionId || initialConnectionId ? "Edit" : "Add"}
              />
              <ConnectionTabs
                displayedBasicDriverProperties={displayedBasicDriverProperties}
              />
            </Form>
          </Col>
          {!props.isInitialSetupPage && <Flyout />}
        </Row>
        <div hidden={!processingRequest}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
        <PromptWithModal
          when={unsavedChanges}
          navigate={(path: string) => Navigate({ to: path })}
        />
      </Container>
    </ConnectionContext.Provider>
  );
}

export default EditConnection;
