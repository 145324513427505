import { useState, useEffect } from "react";
import { updateRequiredProperties } from "./ConnectionFunctions";
import { Button, Spinner, Form } from "reactstrap";
import Loader from "../../../components/Loader";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import { RequestType } from "../../../components/withAPI";
import { CustomReportForm } from "./CustomReportForm";
import { ICustomReportInfo, ICustomReportParameter } from "../../../models";
import { useAPI } from "../../../components/useAPI";
import {
  parseReportNameParameter,
  sortAndShiftParameters,
} from "./CustomReportFunctions";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import { compareStrings } from "../../../utility/CompareStrings";
import { ICreateCustomReportResponse } from "../../../models/CustomReports/ICreateCustomReportResponse";

export interface ICustomReportListItem {
  id: string | undefined;
  name: string;
  rawName: string;
  parameters?: {};
  reportType?: string;
}

interface ICustomReportFormModalProps {
  selectedReport: ICustomReportListItem | null | undefined;
  reportTypes: string[];
  connectionId: string;
  driver: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  onBackButton: () => void;
  onSaveReport: (status: number, report: ICreateCustomReportResponse) => void;
}

/** Renders the custom report form in a modal. ONLY used in the initial setup wizard at `/initial-setup` */
export const CustomReportFormModal = (props: ICustomReportFormModalProps) => {
  const {
    selectedReport,
    reportTypes,
    connectionId,
    driver,
    isModalOpen,
    onCloseModal,
    onBackButton,
    onSaveReport,
  } = props;

  const api = useAPI();

  // True while loading the custom report information we need to display the form.
  const [loading, setLoading] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  // True while the form is being saved.
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const [mandatoryNameProperties, setMandatoryNameProperties] = useState<
    string[]
  >([]);
  const [customReportProperties, setCustomReportProperties] = useState<
    ICustomReportParameter[]
  >([]);
  const [requiredProperties, setRequiredProperties] = useState({});
  const [customReportId, setCustomReportId] = useState<string>("");

  async function getCustomReportProperties() {
    setLoading(true);

    const { status, payload } = await api.callAPI<ICustomReportInfo>(
      RequestType.Get,
      `/utility/drivers/${props.driver}/customReports/${selectedReport?.rawName}`,
      "Failed to get report parameters due to the following error:",
    );

    if (status === 200) {
      const nameParameters = parseReportNameParameter(
        payload!.parameters ?? [],
      );
      const sortedShiftedParameters = sortAndShiftParameters(
        payload!.parameters ?? [],
      );

      setMandatoryNameProperties(nameParameters);
      updateRequiredProperties(sortedShiftedParameters, setRequiredProperties);
      setCustomReportProperties(sortedShiftedParameters);
    } else {
      // We can't display a custom report, send them back.
      onBackButton();
    }

    setLoading(false);
  }

  useEffect(() => {
    getCustomReportProperties();
  }, []); // eslint-disable-line

  // Tracks input updates for the report forms in the onboarding interface
  const inputUpdated = async () => {
    setUnsavedChanges(true);
  };

  // Reads the form data and submits it to create a custom report. Only used in the onboarding interface.
  async function handleValidSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    setIsSaving(true);

    const formData = new FormData(event.target as HTMLFormElement);
    const name = Object.fromEntries(formData.entries()).name?.toString();
    for (const nameProperty of mandatoryNameProperties) {
      formData.append(nameProperty, name);
    }
    const values = Object.fromEntries(formData.entries());
    delete values["name"];

    //updating error state for fields if any error exist return submit function
    let errorFound = false;
    const errorMessages: Record<string, string> = {};
    for (const key in values) {
      if (
        compareStrings(values[key] as string, "Select") &&
        requiredProperties[key as keyof typeof requiredProperties]
      ) {
        errorFound = true;
        errorMessages[key] = `${key} is a required field`;
      } else {
        errorMessages[key] = "";
      }
    }
    setErrors(errorMessages);

    if (errorFound === true) {
      setIsSaving(false);
      return;
    }

    //updating select values as "".
    for (const key in values) {
      if (compareStrings(values[key] as string, "Select")) delete values[key];
    }

    const newDataSet: {
      Name: string;
      Parameters: any;
      ReportType: string | undefined;
    } = {
      Name: name,
      Parameters: values,
      ReportType: selectedReport?.rawName,
    };

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/AddEditCustomReport/${connectionId}/driver/${driver}/${customReportId ? customReportId : undefined}`,
      "Failed to save custom report due to the following error:",
      newDataSet,
    );

    setIsSaving(false);

    if (status === 200) {
      if (payload.testCustomReportError) {
        setCustomReportId(payload.customReport.id);

        ToastrError(
          "Unable to successfully test your custom report",
          payload.testCustomReportError.error!.message,
        );
      } else {
        setCustomReportId("");
        setUnsavedChanges(false);

        ToastrSuccess(
          "Custom Report Saved Successfully!",
          `Custom report ${payload.customReport?.name} has been successfully saved.`,
        );
      }
    }

    onSaveReport(status, payload);
  }

  const title = customReportId ? "Edit Custom Report" : "Add Custom Report";

  return (
    <>
      <CDataModalV2
        title={title}
        displayed={isModalOpen}
        close={() => {
          setCustomReportId("");
          onCloseModal();
        }}
        primaryButton={
          <Button
            disabled={!unsavedChanges || isSaving}
            color="primary"
            type="submit"
            form="formProps"
            data-testid="button-confirm-custom-report"
          >
            Confirm
          </Button>
        }
        secondaryButton={
          <Button
            hidden={reportTypes?.length === 1}
            className="borderless-button modal-back-button"
            onClick={() => {
              onBackButton();
            }}
            data-testid="button-open-report-modal"
          >
            Back
          </Button>
        }
      >
        {loading && <Loader />}
        {!loading && (
          <div>
            <div hidden={!isSaving}>
              <div className="loading-background" />
              <Spinner
                className="spinner-border loading-spinner"
                color="info"
              />
            </div>
            <p>Set the parameters for your report.</p>
            <Form id="formProps" onSubmit={handleValidSubmit}>
              <CustomReportForm
                customReportName=""
                inputUpdated={inputUpdated}
                customReportProperties={customReportProperties}
                useOnboardingFlowInterface={true}
                errors={errors}
              />
            </Form>
          </div>
        )}
      </CDataModalV2>
    </>
  );
};
